.sidebar {
    border-radius: 3rem;
    background-color: #e5e1ff;
}

// .sidebarItem:hover {
//     color: white;
//     background: #5D4EC0;
// }

.Mui-selected {
    background: black;
}
