//
// components.scss
//

#component-title {
    font-weight: bold;
    font-size: x-large;
    color: #707070;
}

.component-form {
    padding: 1rem;

    label {
        display: block;
    }
}

#component-icon {
    float: right;
    padding: 3%;
}